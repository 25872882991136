.payment {
    position: relative;
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    text-align: center;
    justify-content: center;
    height: calc(100vh - 150px);
}

.payment_label_1 {
    font-size: 56px;
    display: flex;
    color: #405469;
    margin-bottom: 5px;
}

.payment_label_2 {
    display: flex;
    font-size: 31px;
    color: #405469;
}

.payment_form {
    font-size: 30px;
}

.payment_card_current {
    display: flex;
    font-size: 18px;
    color: #405469;
    margin-top: 80px;
    width: 100%;
    justify-content: space-evenly;
    max-width: 600px !important;
}

.payment_card_current > div {
    text-align: center;
}

.payment_card_current > div:nth-child(2) {
    display: flex;
    align-items: center;
}

.payment_header {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.payment_content {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 50px;
}

.payment_content .loading_container_card {
    margin-top: -20px;
    font-family: "Rubik_regular";
    font-size: 20px;
    display: flex;
    justify-content: center;
}

.payment_content .loading_container_card .MuiCircularProgress-root {
    margin-right: 10px;
}

.payment_content .clear_edit {
    text-align: center;
    cursor: pointer;
}

.payment_current_card {
    display: flex;
    align-items: center;
}

.payment_current_card div {
    margin: 0px 10px;
}

.payment_check {
    display: flex;
    align-items: center;
}

.payment_actions {
    margin-top: 50px;
}

.payment_card_elem_actions {
    display: flex;
    justify-content: center;
    margin-bottom: 10px;
}

.payment_content .StripeElement {
    width: 400px;
    margin: auto;
}

 @media only screen and (max-width: 1249px) {
     .payment_label_1 {
         font-size: 34px;
     }

     .payment_label_2 {
         font-size: 20px;
     }
 }

 @media only screen and (max-width: 899px) {
    .payment_content .loading_container_card {
        margin-top: -14px;
        font-size: 14px;
    }
    
    .payment_content .loading_container_card .MuiCircularProgress-root {
        margin-right: 10px;
        width: 17px !important;
        height: 17px !important;
    }
}

 @media only screen and (max-width: 599px) {
    .payment_label_1 {
        font-size: 28px;
    }
     
    .payment_label_2 {
        font-size: 15px;
    }

    .payment_content .StripeElement {
        width: 300px;
        margin: auto;
    }

    .payment_current_card div {
        margin: 0px 5px;
    }

    .payment_current_card {
        font-size: 10px;
    }
}

 @media only screen and (max-width: 449px) {
    .payment_label_1 {
        font-size: 22px;
    }
     
    .payment_label_2 {
        font-size: 13px;
    }

    .payment_content .StripeElement {
        width: 250px;
        margin: auto;
    }
}

@media only screen and (max-width: 399px) {
   .payment_label_1 {
       font-size: 20px;
   }
    
   .payment_label_2 {
       font-size: 11px;
   }

   .payment_content .StripeElement {
       width: 250px;
       margin: auto;
   }
}
.sidebar, .sidebar_mini {
    display: flex;
    flex-direction: column;
    background: #ffffff;
    width: 250px;
    height: 100%;
    border-right: 1px lightgray dotted;
    position: fixed;
    z-index: 101;
}

.sidebar_banner_on {
    height: calc(100% - 50px);
}

.sidebar_mini {
    width: 70px;
}

.sidebar_mini .sidebarItem_line {
    margin-right: 0px;
}

.sidebar_mini .notifications_content {
    left: 45px;
    top: 50px;
    height: 70vh;
    border: 1px solid lightgray;
}

.sidebar_header {
    height: 70px;
    text-align: center;
    justify-content: center;
    display: flex;
    margin-bottom: 20px;
    border-bottom: 1px lightgray dotted;
}

.sidebar_logo {
    width: 100px;
    margin: 5px 0;
}

.sidebar_logo_mini {
    width: 50px;
}

.sidebar_label_1 {
    font-size: 14px;
    color: #121317;
    text-align: center;
}

.sidebar_items {
    display: flex;
    flex: 1;
    flex-direction: column;
    margin-top: 30px;
}

.sidebar_footer {
    display: flex;
    align-items: center;
    justify-content: center;
    height: auto;
    padding: 10px;
    font-size: 17px;
}

.sidebar_footer_info {
    display: flex;
    flex-direction: column;
    width: 100px;
    margin-left: 20px;
}

.sidebar_footer_info .expertise {
    font-size: 14px;
}

.sidebar_footer img {
    width: 60px;
    height: 60px;
    border-radius: 100%;
    flex-shrink: 0;
}

.sidebar_copy_link {
    text-align: center;
    margin-bottom: 20px;
}

.sidebar_mini .sidebar_copy_link .home_copy_link_btn {
    padding: 4px 10px!important;
}

@media only screen and (max-width: 999px) {
    .sidebar_items {
        margin-top: 10px;
    }

    .sidebar_header {
        margin-bottom: 20px;
    }
}

@media only screen and (max-width: 449px) {
    .sidebar_mini {
        width: 50px;
    }

    .sidebar_logo_mini {
        width: 35px;
    }

    .sidebar_footer img {
        width: 40px;
        height: 40px;
    }

    .sidebar_logout {
        min-width: 50px !important;
    }
}

@media only screen and (max-width: 399px) {
    .sidebar_copy_link {
        margin-bottom: 0;
        padding: 4px 0 !important;
    }
}

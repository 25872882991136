@font-face {
  font-family: Rubik_regular;
  src: url("fonts/rubik/Rubik-Regular.ttf");
}

@font-face {
  font-family: Rubik_medium;
  src: url("fonts/rubik/Rubik-Medium.ttf");
}

@font-face {
  font-family: Rubik_light;
  src: url("fonts/rubik/Rubik-Light.ttf");
}

@font-face {
  font-family: Rubik_regular;
  src: url("fonts/rubik/Rubik-Regular.ttf");
}

@font-face {
  font-family: Rubik_bold;
  src: url("fonts/rubik/Rubik-Bold.ttf");
}

@font-face {
  font-family: Montserrat_regular;
  src: url("fonts/montserrat/Montserrat-Regular.ttf");
}

@font-face {
  font-family: Montserrat_semibold;
  src: url("fonts/montserrat/Montserrat-SemiBold.ttf");
}

@font-face {
  font-family: Montserrat_medium;
  src: url("fonts/montserrat/Montserrat-Medium.ttf");
}

@font-face {
  font-family: Montserrat_bold;
  src: url("fonts/montserrat/Montserrat-Bold.ttf");
}

@font-face {
  font-family: Opensans_regular;
  src: url("fonts/opensans/OpenSans-Regular.ttf");
}

@font-face {
  font-family: Opensans_semibold;
  src: url("fonts/opensans/OpenSans-SemiBold.ttf");
}

@font-face {
  font-family: Playfair_Display_regular;
  src: url("fonts/playfairdisplay/PlayfairDisplay-Regular.ttf");
}

@font-face {
  font-family: Oswald_regular;
  src: url("fonts/Oswald/Oswald-Regular.ttf");
}

body {
  margin: 0;
  font-family: "Opensans_regular";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  overflow-y: scroll;
}

.body_no_scrolling::-webkit-scrollbar {
  display: none;
}

.body_no_scrolling {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.quadrado {
  background: #000;
  animation: crescendo 0.9s alternate infinite ease-in-out;
}

@keyframes crescendo {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.4);
  }
}

.footer {
  padding-bottom: 10px;
}

.footer_label {
  font-family: "Opensans_regular";
  font-size: 12px;
  text-align: center;
}

.footer_button_store > div {
    width: 68px !important;
    height: 20px !important;
    margin: 0 2px;
}

.footer_button_store > div > a {
    padding: 0 !important;
}

.heading {
  font-family: 'Playfair_Display_regular' !important;
}

button {
  border-radius: 16px !important;
  box-shadow: none !important;
  font-family: "Rubik_regular" !important;
}

.MuiFormControl-root input {
  font-family: "Opensans_regular";
  position: relative;
}

.MuiBadge-dot {
  width: 12px !important;
  height: 12px !important;
  border-radius: 100% !important;
  margin-top: 1px;
  margin-right: 2px;
}

@media only screen and (max-width: 649px) {
  .footer_label {
    font-size: 10px;
  }

  .icon_info_button svg {
    width: 17px;
    height: 17px;
  }
}

.booking_card_container {
    background: rgba(255, 255, 255, 0.5);
    border-radius: 7px;
    margin: 10px 0;
    width: 100%;
}

.booking_card_container_border {
    border: 1px solid lightgray
}

.booking_card {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 15px 25px;
    border-radius: 7px;
    position: relative;
}

.booking_card_accepted {
    background: #baffcd;
}

.booking_card_declined {
    background: #ffdbec;
}

.booking_card_footer {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    text-align: center;
    margin: 0 10px;
    margin-bottom: 20px;
}

.booking_card_footer .top_divider {
    margin-top: 5px;
}

.booking_card_footer .bottom_divider {
    margin-bottom: 15px;
}

.column_footer {
    flex-direction: column;
}

.booking_card_header {
    margin-bottom: 0;
    margin-top: 10px;
}

.booking_card_footer > h6 {
    width: 100%;
}

.booking_card_footer .download_button {
    min-width: 135px;
    min-height: 30px;
    margin-bottom: 10px;
    font-size: 11px !important;
}

.booking_card_header_name {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
}

.booking_card_header_name .chat {
    width: 30px;
}

.booking_card_header_name .chat button {
    margin-top: 5px;
}

.booking_card_col {
    display: flex;
    flex-direction: column;
    width: 20%;
    margin: 0 10px;
}

.booking_card_col > *:not(:last-child) {
    margin-right: 5px;
}

.booking_card_col.row {
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.booking_card_col .call_chat_container {
    background-color: #34d980;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.booking_card_col .call_chat_container svg {
    width: 20px;
    height: 20px;
    color: white;
}

.start_call_text .test_call_button {
    padding: 0;
    min-width: 80px;
    border: none;
    margin-left: 10px;
}

.start_call_text .test_call_button:hover {
    border: none;
}

.booking_card .label_type_2 {
    font-size: 20px !important;
    text-align: left !important;
    color: #2f455c !important;
    margin: 0px !important;
    word-break: break-word;
}

.booking_card .label_type_1 {
    text-align: left !important;
    color: #2f455c !important;
    font-size: 14px !important;
    margin: 0px !important;
}

.booking_card .label_type_1_center {
    text-align: center !important;
}

.booking_card .booking_center {
    text-align: center !important;
    align-items: center;
}

.booking_card .booking_center .label_type_1 {
    text-align: center !important;
}

.booking_card .booking_card_count {
    text-align: center;
    font-weight: bold;
}

.booking_card .booking_card_actions {
    position: absolute;
    right: 7px;
    color: slategray;
    cursor: pointer;
}

.booking_card .booking_card_icon {
    width: 10%;
}

.booking_card .chat_icon {
    color: initial;
}

.booking_card .chat_icon_pointer {
    cursor: pointer;
}

.booking_card .chat_icon_disable_container {
    border-radius: 100%;
    background-color: white;
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.booking_card .booking_card_icon .recording_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-width: 50px;
}

.booking_card .booking_card_icon .recording_container p {
    margin-top: 0;
    margin-bottom: 5px;
    font-size: 14px;
}

.booking_card .booking_card_icon .recording_container svg {
    cursor: pointer;
}

.booking_card .booking_card_actions .menu_booking_card_actions {
    position: relative;
}

.booking_card .booking_card_actions .MuiMenu-paper {
    top: 0;
}

.booking_accepted_status {
    font-weight: bold;
}

.booking_pending_status {
    color: orange;
}

.booking_card .selection_icon {
    display: flex;
    width: 30px;
    height: 30px;
    border-radius: 100%;
    align-items: center;
    justify-content: center;
}

.booking_icon_button {
    margin: 0 auto !important;
    padding: 0 !important;
}

.booking_notif_buttons {
    display: flex;
    flex-direction: row;
}

.booking_declined_button {
    color: #ff0077 !important;
}

.booking_card_column {
    flex-direction: column !important;
}

.booking_card .session_price_call {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.booking_card .session_price_call p {
    margin-top: 0;
    margin-bottom: 7px;
}

.booking_card .session_price_call .call_button {
    padding: 0;
    padding-bottom: 5px;
}

.booking_card .session_price_call .call_button .MuiIconButton-label {
    width: 50px;
    height: 50px;
}

.make_appointment_button {
    font-weight: bold !important;
    font-size: 13px !important;
}

.book_next_reading {
    margin-top: 10px !important;
    padding: 7px 30px !important;
}

.start_call_text {
    justify-content: flex-start;
    text-align: start;
    padding-left: 30px;
    padding-right: 30px;
}

.verify_email_container {
    margin-bottom: 15px;
}

.verify_email_text {
    color: red;
    font-style: italic;
}

.booking_card .booking_card_date_with_points {
    align-items: center;
}

.booking_card .booking_card_date_with_points .booking_pending_status {
    color: red;
    font-size: 13px;
}

.booking_card .booking_waiting_call {
    width: calc(20% + 10px);
    margin: 0 5px;
}

.booking_waiting_call .MuiTypography-root {
    font-weight: bold;
}

.booking_card .booking_notes_small {
    text-align: center;
}

@media only screen and (max-width: 899px) {
    .booking_card .booking_card_icon .recording_container p {
        font-size: 11px;
        margin-bottom: 2px;
    }

    .booking_card .label_date {
        font-size: 11px !important;
    }

    .start_call_text {
        padding-left: 20px;
        padding-right: 20px;
    }

    .booking_card .booking_waiting_call .MuiTypography-root {
        font-size: 15px;
    }
}

@media only screen and (max-width: 799px) {
    .navigation .notifications_list .booking_card {
        width: calc(100% - 20px) !important;
    }

    .booking_card_footer > h6 {
        font-size: 12px !important;
    }

    .booking_card_col {
        margin: 0 5px;
    }

    .booking_card .label_type_2 {
        font-size: 17px !important;
    }

    .booking_card .booking_center {
        width: 16%;
    }

    .booking_card .booking_card_count {
        font-size: 14px;
    }

    .booking_card .label_type_1_center, .booking_card .session_price_call {
        font-size: 11px !important;
    }

    .booking_card .booking_orange_button {
        font-size: 12px;
    }

    .booking_card .booking_notes_small {
        width: 16%;
    }

    .booking_card .booking_notes_small .label_type_1 {
        font-size: 12px !important;
    }

    .booking_card .booking_waiting_call {
        width: 16%;
    }

    .booking_card .booking_waiting_call > h6 {
        font-size: 12px !important;
    }

    .make_appointment_button {
        font-size: 12px !important;
    }
}

@media only screen and (orientation: portrait) {
    .booking_card_col {
        justify-content: center;
        width: 30% !important;
    }

    .booking_card_col .call_chat_container {
        width: 30px;
        height: 30px;
    }
    
    .booking_card_col .call_chat_container svg {
        width: 17px;
        height: 17px;
    }

    .booking_waiting_call .MuiTypography-root {
        font-size: 20px;
    }
}

@media only screen and (orientation: portrait) and (max-width: 649px)  {
    .booking_card {
        padding-left: 10px;
    }
    
   .booking_card .booking_card_count {
        font-size: 12px;
    }

    .booking_card_header {
        margin-top: 5px;
    }

    .booking_card_container {
        margin: 5px 0;
    }

    .booking_card_date {
        width: 44% !important;
    }

    .booking_card_footer .booking_orange_button {
        font-size: 15px;
    }

    .start_call_text {
        font-size: 14px;
    }

    .make_appointment_button {
        font-size: 10px !important;
    }
    
    .booking_card_container .no_margin_bottom {
        margin-bottom: 0;
    }
    
    .booking_card_container .no_transformation {
        text-transform: none;
    }

    .start_call_text {
        padding-left: 10px;
        padding-right: 10px;
    }

    .booking_waiting_call .MuiTypography-root {
        font-size: 17px;
        margin: 0 30px;
    }

    .start_call_text .test_call_button {
        min-width: 70px;
        font-size: 12px;
    }
}

@media only screen and (orientation: portrait) and (max-width: 449px)  {
    .booking_card_col {
        width: 26% !important;
    }
    
    .booking_card_footer .chat_Button {
        width: 17px;
        height: 17px;
    }

    .booking_card_date {
        width: 40% !important;
    }

    .booking_card .label_type_2 {
        font-size: 15px !important;
    }

    .booking_card .selection_icon > svg {
        width: 20px;
        height: 20px;
    }

    .booking_card .label_type_1_center, .booking_card .session_price_call {
        font-size: 12px !important;
    }

    .booking_card .label_date {
        font-size: 11px !important;
    }

    .booking_card .booking_card_date_with_points {
        margin-right: 20px;
    }

    .book_next_reading {
        padding: 5px 20px !important;
    }

    .start_call_text {
        font-size: 12px;
    }

    .booking_card .booking_card_date_with_points .booking_pending_status {
        font-size: 12px;
    }

    .booking_card .booking_center {
        font-size: 12px;
    }

    .booking_waiting_call .MuiTypography-root {
        font-size: 15px;
    }
}

@media only screen and (orientation: portrait) and (max-width: 399px)  {
    .booking_card .label_type_2 {
        font-size: 13px !important;
    }

    .booking_card .selection_icon > svg {
        width: 17px;
        height: 17px;
    }

    .booking_card .label_type_1_center, .booking_card .session_price_call {
        font-size: 10px !important;
    }

    .booking_card .booking_card_date_with_points .booking_pending_status {
        font-size: 10px;
    }

    .booking_card_footer > h6 {
        font-size: 11px !important;
    }

    .make_appointment_button {
        font-size: 8px !important;
    }

    .booking_card_footer .download_button {
        min-width: 115px;
    }

    .booking_card .label_date {
        font-size: 9px !important;
    }

    .start_call_text .test_call_button {
        min-width: 55px;
        font-size: 10px;
    }
}

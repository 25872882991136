.profile {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    position: relative;
    padding-top: 7px;
}

.profile p {
    font-size: 15px;
    color: #405469;
    margin: 7px;
}

.profile .header {
    display: flex;
    justify-content: space-between;
    width: 85%;
    margin: auto;
    margin-bottom: 15px;
}

.profile .left_header {
    display: flex;
    margin-right: 30px;
}

.profile .back_button {
    margin-right: 30px;
    width: 35px;
    height: 35px;
    background: white;
}

.profile .back_text {
    display: flex;
    justify-content: center;
    font-size: 12px;
    text-decoration: underline;
    cursor: pointer;
    margin-bottom: 10px;
}

.profile .profile_pic {
    width: 70px;
    height: 70px;
    border-radius: 50%;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.profile .upload_pic_loading {
    display: flex;
    width: 86px;
    height: 82px;
    justify-content: center;
    align-items: center;
}

.profile .names {
    margin-left: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.profile .profile_name {
    font-size: 23px;
    color: #405469;
}

.profile .profile_expertise {
    font-size: 15px;
    color: rgb(189, 189, 189);
}

.profile .right_header {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    min-width: 180px;
}

.profile .right_header > div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 5px;
}

.profile .right_header_label {
    margin-right: 20px;
    font-size: 17px;
    color: #405469;
}

.profile .preview_profile {
    text-align: center;
    margin: 20px;
}

.profile .content {
    display: flex;
    width: 85%;
    margin: auto;
    justify-content: space-evenly;
    flex-direction: column;
}

.profile .content_item {
    display: flex;
    flex-direction: column;
    background-color: white;
    border-radius: 7px;
    padding: 10px 30px;
    margin-bottom: 20px;
}

.profile .content_item .content_label {
    font-size: 20px;
    text-align: center;
    margin-top: 10px;
    margin-bottom: 20px;
    color: black;
}

.profile .form_names {
    display: flex;
    justify-content: space-between;
}

.profile .form_names > div {
    width: 31%;
}

.profile .MuiOutlinedInput-root {
    border-radius: 15px;
    background-color: rgba(0, 0, 0, 0.04);
    color: gray;
    font-weight: bold;
    font-size: 20px;
}

.profile .MuiOutlinedInput-notchedOutline {
    border: none;
}

.profile .divider {
    margin: 15px 0;
    margin-bottom: 8px;
}

.profile .invoicing {
    display: flex;
    justify-content: space-between;
}

.profile .invoicing > div {
    width: 31%;
}

.profile .invoicing_checkbox {
    margin-top: 10px;
}

.profile .buffer_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 10px;
}

.profile .buffer_checkbox {
    margin-right: 0;
}

.profile .hours_buffer {
    min-width: 175px;
}

.profile .hours_buffer .MuiFormControl-root {
    height: 24px;
    width: 80px;
    margin-right: 10px;
}

.profile .hours_buffer .MuiInputBase-root {
    height: 24px;
}

.profile .save_buffor_icon {
    padding: 0;
    padding-left: 15px;
    cursor: pointer;
}

.profile .pricing_container {
    display: flex;
    justify-content: space-evenly;
}

.profile .pricing_container .booking_pricing, .profile .pricing_container .booking_pricing_item {
    display: flex;
}

.profile .pricing_container > div > p {
    margin: 0;
}

.profile .pricing_container .price_p {
    margin: 0;
    font-size: 20px;
    display: inline;
}

.profile .pricing_container .price_item {
    display: flex;
    margin: 0 7px;
    justify-content: space-between;
    align-items: center;
    width: 157px;
}

.profile .pricing_container .checkbox_free .MuiCheckbox-root {
    padding-right: 5px;
}

.profile .pricing_container .checkbox_free .MuiSvgIcon-root {
    width: 17px;
    height: 17px;
}

.profile .pricing_container .checkbox_free .MuiTypography-root {
    font-size: 14px;
}

.profile .pricing_container .error_input .MuiInputBase-root {
    border: 1px red solid;
}

.profile .pricing_container .price_form .MuiInputBase-root {
    background-color: rgba(0, 0, 0, 0.04) !important;
    font-size: 20px;
    font-weight: bold;
    height: 33px;
    width: 100px;
}

.profile .pricing_header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-height: 60px;
}

.profile .pricing_header .pricing_label {
    display: flex;
    margin-right: 5px;
}

.profile .pricing_header .pricing_label button {
    border: none;
    padding: 0;
    min-width: 25px;
    margin: 0 10px;
}

.profile .pricing_header .pricing_label > p {
    color: rgb(189, 189, 189);
}

.profile .booking_calls_label {
    color: rgb(189, 189, 189);
    margin-bottom: 10px !important;
    min-height: 20px;
}

.profile .disable_color {
    color: rgb(189, 189, 189) !important;
}

.profile .disable_input .MuiInputBase-root {
    color: rgb(189, 189, 189) !important;
}

.profile .loading_when_change_container {
    text-align: center;
    min-width: 32px;
}

.profile .footer_buttons {
    display: flex;
    justify-content: flex-end;
}

.profile .footer_buttons button {
    text-transform: none;
    font-size: 17px;
    margin: 0 10px;
}

.profile .footer_buttons .delete_button {
    color: red;
}

.profile .recordings_checkbox {
    margin-top: 20px;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    flex-direction: column;
}

.profile .save_button {
    width: 100%;
    text-align: center;
    margin-top: 20px;
    margin-bottom: 10px;
}

.profile .save_button button {
    font-size: 14px;
    width: 31%;
    text-transform: none;
}

.profile .right_content_child {
    background-color: white;
    border-radius: 7px;
    padding: 10px 20px;
    margin-bottom: 15px;
}

.profile .right_content_child .horizontal_divider_availability {
    margin-bottom: 30px;
}

.profile .right_content_child .buffers_divider {
    margin: 20px 0;
}

.profile .header_container {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}

.profile .header_container .title_info_container,
.profile .buffer_container .title_info_container {
    display: flex;
}

.profile .header_container .red_text {
    color: red;
    font-size: 11px;
    margin-top: 0;
}

.profile .header_container button {
    border: none;
    padding: 5px 20px;
    font-size: 12px;
    text-transform: none;
    margin-left: 20px;
}

.profile .unavailable_dates_calendar {
    display: flex;
    justify-content: center;
    margin: 50px 20px;
}

.profile .unavailable_dates_calendar .rmdp-today span, .specific_dates_dialog_content .rmdp-today span {
    background-color: orange !important;
}

.profile .availability_container {
    display: flex;
    justify-content: space-evenly;
}

.profile .availability_container .timezone_display {
    text-align: center;
    margin: 10px 0;
}

.profile .availability_schedule_container {
    width: 350px;
    margin: auto;
    margin-bottom: 10px;
}

.profile .available_schedule_item {
    display: flex;
    justify-content: space-between;
    margin: 5px 0;
}

.profile .available_schedule_item .times_and_actions {
    display: flex;
}

.profile .available_schedule_item .times_and_actions .remove_icon {
    display: flex;
    align-items: center;
    width: 25px;
}

.profile .available_schedule_item .times_and_actions .remove_icon svg {
    cursor: pointer;
}

.profile .available_schedule_label {
    display: flex;
    align-items: center;
}

.profile .times {
    display: flex;
    justify-content: center;
    flex-direction: column;
    min-width: 130px;
}

.profile .square_disable {
    background-color: lightgray !important;
}

.loading_container_single {
    display: flex;
    margin-top: 200px;
    align-items: center;
}

.sliders_container {
    width: 515px;
    display: flex;
    padding-right: 10px;
}

.sliders_container .MuiSlider-root {
    width: 470px;
}

.sliders_container svg {
    cursor: pointer;
    margin-left: 20px;
}

.add_slider_button_container {
    width: 100%;
    text-align: center;
    margin-top: 5px;
}

.add_slider_button_container svg {
    cursor: pointer;
}

.profile .special_divider {
    margin-top: 60px;
    height: calc(100% - 60px);
}

.availability_dialog .availability_content_dialog {
    padding-right: 5px;
    padding-left: 40px;
}

.availability_dialog .availability_content_dialog .timezones {
    width: calc(100% - 40px);
    margin-bottom: 20px;
}

.availability_dialog .availability_content_dialog .timezones .css-26l3qy-menu {
    margin: 1px 0;
    z-index: 100;
}

.specific_dates {
    text-align: center;
    margin-top: 10px;
    min-width: 320px;
}

.specific_dates_dialog .title {
    text-align: center;
}

.specific_dates_dialog_content {
    width: 550px;
    height: 300px;
    padding-top: 40px !important;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.specific_dates_dialog_content .rmdp-wrapper {
    margin: auto;
}

.specific_dates_dialog .actions {
    display: flex;
    justify-content: space-between;
}

.discount_coupon_container {
    margin-bottom: 20px;
    text-align: center;
}

.discount_coupon_container .discount_coupon_item {
    display: flex;
    width: 70%;
    min-width: 330px;
    max-width: 500px;
    align-items: center;
    margin: 7px auto;
}

.discount_coupon_container .discount_coupon_item p {
    text-align: right;
    margin-left: 7px;
    margin-right: 7px;
}

.discount_coupon_container .discount_coupon_item .name {
    width: inherit;
    text-align: left;
}

.discount_coupon_container .discount_coupon_item .percent {
    min-width: 30px;
}

.discount_coupon_container .discount_coupon_item .expiry_date {
    min-width: 100px;
}

.discount_coupon_container .discount_coupon_item .actions {
    display: flex;
    min-width: 60px;
    justify-content: space-between;
}

.discount_coupon_container button, .discount_coupon_container button:hover {
    border: none;
    margin-top: 20px;
    margin-bottom: 10px;
}

.discount_coupon_container .discount_coupons_table {
    min-width: 1000px;
}

.discount_coupon_container .members_discount_coupons {
    width: 400px;
}

.discount_coupon_container .cut_options_discount_coupons {
    width: 110px;
}

.discount_coupon_container .header_actions_discount_coupons {
    text-align: center;
}

.discount_coupon_container .actions_discount_coupons {
    text-align: center;
    width: 75px;
}

.discount_coupon_container .actions_discount_coupons svg {
    margin: 0 5px;
}

.discount_coupon_container_dialog_content {
    height: 420px;
    max-width: 500px;
}

.discount_coupon_container_dialog_content .all_members_checkbox {
    padding-top: 0 !important;
}

.discount_coupon_container_dialog_content .all_members_checkbox .MuiFormControlLabel-root {
    align-items: inherit;
}

.discount_coupon_container_dialog_content .all_members_checkbox .MuiCheckbox-root {
    padding-top: 0;
}

.discount_coupon_container_dialog_content .delivery_date .rmdp-container {
    width: 100%;
    height: 100% !important;
}
  
.discount_coupon_container_dialog_content .delivery_date .rmdp-container > div:first-of-type {
    height: 100%;
}
  
.discount_coupon_container_dialog_content .delivery_date .rmdp-container > div:first-of-type input {
    width: calc(100% - 21px);
    height: 34px;
    font-size: 15px;
    padding-left: 14px;
}

.discount_coupon_container_dialog_actions .create_button {
    width: 70px;
}

.pricing_info_button {
    padding-left: 0 !important;
}

@media only screen and (max-width: 1439px) {
    .profile .header {
        width: 95%;
    }
    
    .profile .content {
        width: 95%;
    }

    .profile .special_divider, .profile .pricing_divider {
        margin-left: 10px;
        margin-right: 10px;
    }
}

@media only screen and (max-width: 1279px) {
    .profile .availability_schedule_container {
        width: 270px;
    }

    .profile .booking_calls_label, .profile .pricing_container p {
        font-size: 12px;
    }

    .profile .pricing_container .price_p {
        font-size: 17px;
    }

    .profile .pricing_container .price_form .MuiInputBase-root {
        font-size: 17px;
        width: 85px;
    }

    .profile .pricing_container .price_item {
        width: 135px;
    }

    .discount_coupon_container .members_discount_coupons {
        width: 300px;
    }
}

@media only screen and (max-width: 849px) {
    .profile .availability_schedule_container {
        width: 240px;
    }

    .profile .unavailable_dates_calendar {
        margin: 30px 10px;
    }

    .profile .pricing_container {
        flex-direction: column;
        align-items: center;
    }

    .profile .pricing_header {
        justify-content: start;
    }

    .profile .price_form_per_min {
        display: flex;
    }

    .profile .pricing_divider {
        margin-top: 20px;
        width: 100%;
    }

    .profile .special_divider {
        margin-left: 5px;
        margin-right: 5px;
    }
}

@media only screen and (max-width: 769px) {
    .profile .content_item {
        padding: 10px 20px;
    }

    .profile .back_button {
        margin-right: 20px;
    }

    .profile .availability_container {
        flex-direction: column;
        align-items: center;
    }

    .profile .right_content_child {
        width: 350px;
    }

    .profile .availability_schedule_container {
        width: 350px;
    }

    .profile .unavailable_dates_calendar {
        margin: 0;
        margin-top: 20px;
        margin-bottom: 30px;
    }
}

@media only screen and (max-width: 669px) {
    .specific_dates_dialog_content {
        width: 380px;
    }

    .sliders_container {
        width: 345px;
    }

    .sliders_container .MuiSlider-root {
        width: 310px;
    }

    .sliders_container svg {
        margin-left: 10px;
    }
}

@media only screen and (max-width: 649px) {
    .profile .header, .profile .content {
        width: 90%;
    }

    .profile .header {
        margin-bottom: 10px;
    }

    .profile .profile_pic {
        width: 50px;
        height: 50px;
    }

    .profile .names {
        margin-left: 5px;
    }

    .profile .profile_name {
        font-size: 17px;
    }

    .profile .footer_buttons {
        flex-direction: column;
    }

    .profile .profile_expertise {
        font-size: 13px;
    }

    .profile .right_header_label {
        font-size: 13px;
        margin-right: 10px;
    }

    .profile p, .profile .MuiOutlinedInput-root {
        font-size: 15px;
    }

    .profile .available_schedule_label p {
        font-size: 12px;
    }

    .profile .content_item .content_label {
        margin-top: 5px;
        margin-bottom: 10px;
    }

    .profile .price_form_per_min {
        flex-direction: column;
    }

    .profile .pricing_container .booking_pricing, .profile .pricing_container .booking_pricing_item {
        flex-direction: column;
    }

    .profile .special_divider {
        height: 1px;
        margin-top: 20px;
    }

    .profile .pricing_header {
        grid-column: span 2;
        justify-content: center;
        min-height: 10px;
    }

    .profile .pricing_header .pricing_label {
        margin: 10px 0;
    }

    .profile .booking_pricing .priceForm {
        display: grid;
        grid-template-columns: 50% 50%;
    }

    .profile .booking_calls_label {
        grid-column: span 2;
    }

    .profile .form_names, .profile .invoicing {
        flex-direction: column;
    }

    .profile .form_names > div, .profile .invoicing > div {
        width: 100%;
    }

    .profile .save_button button {
        width: 200px;
    }

    .profile .footer_buttons button {
        font-size: 14px;
    }

    .profile .left_header {
        margin-right: 10px;
    }

    .profile .right_header {
        min-width: 135px;
    }

    .profile .right_content_child, .profile .availability_schedule_container {
        width: 270px;
    }

    .profile .availability_container .timezone_display {
        font-size: 14px;
    }

    .availability_dialog .MuiDialog-paper {
        width: 400px;
    }

    .specific_dates {
        min-width: 290px;
    }
    
    .profile .available_schedule_item .times_and_actions .remove_icon svg {
        width: 20px;
        height: 20px;
    }
}

@media only screen and (max-width: 449px) {
    .profile .header {
        flex-direction: column;
    }

    .profile .right_header {
        min-width: 0;
        align-items: center;
        margin: 5px 0;
    }

    .profile .right_content_child, .profile .availability_schedule_container {
        width: 250px;
    }

    .profile .booking_calls_label, .profile .pricing_container p {
        font-size: 11px;
    }

    .profile .pricing_container .price_p {
        font-size: 15px;
    }

    .profile .pricing_container .price_form .MuiInputBase-root {
        font-size: 15px;
        width: 75px;
        height: 27px;
    }

    .profile .pricing_container .price_item {
        width: 125px;
    }

    .profile .price_form_per_min, .profile .pricing_container .booking_pricing_item {
        justify-content: center;
    }

    .profile .special_divider {
        margin-left: 15px;
        margin-right: 15px;
    }

    .profile .pricing_per_min {
        margin-left: 10px;
    }

    .sliders_container .MuiSlider-root {
        width: 260px;
    }
}

@media only screen and (max-width: 499px) {
    .availability_dialog .MuiDialog-paper {
        width: 300px;
    }

    .sliders_container {
        width: 225px;
    }

    .specific_dates {
        min-width: 260px;
    }

    .specific_dates_dialog_content {
        width: 260px;
    }

    .sliders_container .MuiSlider-root {
        width: 190px;
    }

    .discount_coupon_container .discount_coupon_item {
        min-width: 250px;
    }

    .discount_coupon_container .discount_coupon_item p {
        font-size: 12px;
    }

    .discount_coupon_container .discount_coupon_item .percent {
        min-width: 25px;
    }
    
    .discount_coupon_container .discount_coupon_item .expiry_date {
        min-width: 65px;
    }
    
    .discount_coupon_container .discount_coupon_item .actions {
        min-width: 40px;
    }
    
    .discount_coupon_container .discount_coupon_item .actions .MuiSvgIcon-root {
        width: 17px;
        height: 17px;
    }
}

@media only screen and (max-width: 449px) {
    .discount_coupon_container .discount_coupon_item {
        width: 250px;
        min-width: 0;
    }

    .profile .header_container .title_info_container,
    .profile .buffer_container .title_info_container .MuiFormControlLabel-label {
        font-size: 13px;
    }
}

@media only screen and (max-width: 399px) {
    .profile .invoicing_checkbox {
        margin-right: 0;
    }

    .profile .booking_pricing .priceForm {
        grid-template-columns: 100%;
    }

    .profile .booking_calls_label {
        grid-column: span 1;
    }

    .profile .pricing_header {
        grid-column: span 1;
    }

    .profile .invoicing_checkbox .MuiTypography-root {
        font-size: 13px !important;
    }

    .profile .price_form_per_min, .profile .pricing_container .booking_pricing_item {
        flex-direction: column;
    }

    .profile .special_divider {
        margin-left: 0;
        margin-right: 0;
    }

    .profile .pricing_per_min {
        margin-left: 0;
    }

    .profile .pricing_header {
        justify-content: center;
    }

    .profile .pricing_divider {
        margin-top: 15px;
    }

    .profile .pricing_header, .profile .booking_calls_label {
        min-height: 0;
    }

    .profile .right_content_child {
        padding: 10px 0;
    }

    .availability_dialog .MuiDialog-paper {
        width: 280px;
    }

    .profile .pricing_container .price_form .price_form_item {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .profile .booking_pricing .price_form {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .profile .pricing_divider, .profile .special_divider {
        margin: 20px auto 7px auto;
        width: 137px;
    }

    .specific_dates_dialog_content {
        width: 247px;
    }

    .profile .recordings_checkbox .MuiFormControlLabel-label {
        font-size: 14px;
    }
}

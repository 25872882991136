.navigation {
    display: flex;
    padding: 0 30px;
    justify-content: space-between;
    align-items: center;
    position: relative;
    height: 100%;
    color: #233b78;
    font-size: 17px;
}

.navigation_client {
    justify-content: flex-end;
}

.navigation_avail {
    margin-right: 0 !important;
}

.navigation_avail .MuiFormControlLabel-label {
    margin-right: 20px;
    font-size: 15px;
}

.availability_info_button {
    padding: 0 !important;
    margin-left: 10px !important;
}

.navigation_zone1 {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-evenly;
    align-items: flex-start;
    width: 370px;
}

.navigation_zone2 {
    height: 100%;
    display: flex;
    align-items: center;
    min-width: 165px;
}

.navigation_zone2_home {
    height: 60px;
    min-width: 300px;
    justify-content: center;
}

.navigation_zone2 .manage_team_create {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: flex-start;
}

.navigation_zone2 .manage_team_container {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.navigation_zone2 .manage_team_container .loading_box {
    display: flex;
    justify-content: center;
    height: 100%;
}

.navigation_zone2 .manage_team_btn {
    padding: 3px 30px !important;
    font-size: 13px;
}

.navigation_zone2 .manage_team_link {
    padding: 3px 5px;
    padding-bottom: 0;
    font-size: 12px;
}

.navigation_zone2 .manage_team_buttons {
    height: 100%;
    align-items: center;
    display: flex;
    width: 100%;
}

.navigation_zone3 {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-right: 15px;
    width: 265px;
}

.navigation_client .navigation_zone3 {
    width: 150px;
}

.navigation_zone3 .consultantBalance {
    width: 50%;
    display: flex;
    font-size: 22px;
    align-items: center;
    justify-content: flex-end;
}

.navigation_zone3 > div:not(:last-child) {
    margin-right: 30px;
}

.navigation_zone3 .MuiButton-root {
    min-width: unset !important;
    padding: 0px 10px !important;
    width: 0px;
}

.navigation_zone3 .notifications_icon {
    height: 20px;
}

.navigation_zone3 .navigation_logout_button {
    height: 20px;
}

.navigation_zone2_client .home_btn_setup_payment {
  margin-top: 0 !important;
}

.notifications_content {
    position: absolute;
    right: 0;
    margin-right: 20px;
    height: 88vh;
    width: 600px;
    background: white;
    z-index: 100;
    border-radius: 7px;
    border: 1px solid rgb(225, 225, 225);
}

.notifications_list {
    height: 100%;
    overflow-y: auto;
    padding: 0 !important;
}

.notifications_list > li {
    padding: 0;
}

.notifications_list .booking_card_container {
    width: 100%;
}

.notifications_list .booking_card {
    padding: 0 10px;
    width: calc(100% - 20px);
}

.notifications_list .booking_card_col_small {
    width: 16% !important;
    margin: 0 10px;
}

.notifications_list .booking_card_col_big {
    width: 26% !important;
    margin: 0 10px;
}

.notifications_list .booking_card_date {
    width: 32% !important;
}

.notifications_list .notif_client {
    display: flex;
    align-items: center;
    margin: 0 20px;
}

.notifications_list .loading {
    width: 100%;
    text-align: center;
    margin-top: 50px;
}

.notifications_list .notif_client .booking_declined_button {
    width: 25px;
    margin-left: 10px;
    text-align: center;
    cursor: pointer;
}

@media only screen and (max-width: 1279px) {
    .navigation {
        padding: 0 10px;
    }

    .navigation_zone1 {
        width: 350px;
    }

    .navigation_zone3 {
        width: 350px;
    }
}

@media only screen and (max-width: 1099px) {
    .navigation {
        padding: 0 10px;
    }

    .navigation_avail .MuiFormControlLabel-label {
        margin-right: 10px;
    }
}

@media only screen and (max-width: 849px) {
    .navigation_zone3 {
        width: 250px;
    }
}

@media only screen and (max-width: 649px) {
    .navigation_zone3 {
        margin-right: 10px;
    }
}

@media only screen and (orientation: portrait) and (max-width: 649px) {
    .notifications_button {
        width: 100%;
        margin-bottom: 10px !important;
    }

    .navigation_zone3 {
        width: 150px;
    }

    .navigation_zone3 .consultantBalance {
        width: 100%;
    }

    .notifications_content {
        width: 350px;
    }

    .notifications_content .notif_client p {
        font-size: 14px;
    }

    .sidebar_mini .notifications_list .booking_card_col_big {
        width: 40% !important;
        margin: 0 5px;
    }

    .sidebar_mini .notifications_list .booking_card_col_small {
        width: 20% !important;
        margin: 0 5px
    }

    .sidebar_mini .booking_card .label_type_1 {
        font-size: 14px !important;
    }
}

@media only screen and (orientation: portrait) and (max-width: 529px) {
    .notifications_button {
        width: 100%;
        margin-bottom: 10px !important;
    }

    .navigation_client .navigation_zone3 {
        width: 100px;
    }

    .navigation_zone3 {
        width: 120px;
    }

    .navigation_zone3 .consultantBalance {
        width: 100%;
    }
}

@media only screen and (max-width: 449px) {
    .navigation {
        padding: 0;
    }

    .navigation_zone3 .consultantBalance {
        font-size: 15px;
    }

    .navigation_zone3 {
        width: 85px;
        margin-right: 15px;
    }

    .notifications_content {
        width: 300px;
    }

    .notifications_content .notif_client p {
        font-size: 12px;
    }

    .sidebar_mini .booking_card .label_type_1 {
        font-size: 12px !important;
    }

    .sidebar_mini .booking_card .booking_card_col_big  {
        width: 30% !important;
    }

    .sidebar_mini .booking_card .booking_card_date {
        width: 40% !important;
    }

    .sidebar_mini .booking_card .label_type_1 {
        font-size: 10px !important;
    }
}

@media only screen and (max-width: 399px) {
    .navigation_avail .MuiFormControlLabel-label {
        font-size: 11px;
    }

    .navigation_zone2 .manage_team_btn {
        font-size: 10px;
    }

    .navigation_zone2 .manage_team_link {
        font-size: 9px;
    }

    .navigation_zone3 .consultantBalance {
        font-size: 15px;
    }

    .navigation_zone3 > div:not(:last-child) {
        margin-right: 10px;
    }
}

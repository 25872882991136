.account_details {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  padding: 20px 0;
}

.account_details .profile_header {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
}

.account_details .profile_label_1 {
  font-size: 34px;
  display: flex;
  color: #405469;
}

.account_details .profile_label_2 {
  display: flex;
  font-size: 20px;
  color: #405469;
}

.account_details .profile_pic_image {
  width: 130px;
  height: 130px;
  border-radius: 100%;
}

.account_details .credit_coupon_container {
  display: flex;
  align-items: center;
}

.account_details .credit_coupon_container .credit_coupon {
  display: flex;
  align-items: center;
  height: 50px;
}

.account_details .credit_coupon_container .credit_coupon .form_control {
  display: contents;
}

.account_details .credit_coupon_container .credit_coupon .form_control .MuiInputBase-root {
  width: 75px;
  height: 20px;
  margin: 10px 10px 10px 5px;
  font-weight: bold;
}

.account_details .credit_coupon_container .credit_coupon .loading {
  min-width: 20px;
}

.account_details .form_items {
  margin-top: 20px;
  width: 70%;
}

.account_details .form_items .MuiInputBase-root {
  background-color: white;
  border-radius: 10px;
}

.account_details .button_centered {
  text-align: center;
}

.account_details .button_submit button {
    padding: 6px 50px;
    margin: 10px 0;
    color: white;
    font-size: 17px;
    text-transform: none;
}

.account_details .delete_button button {
  color: red;
}

.birth_infos_form {
  margin: 0;
  width: 100%;
  height: 370px;
}

@media only screen and (max-width: 649px) and (orientation: portrait) {
  .account_details .profile_label_1 {
    font-size: 26px;
  }

  .account_details .profile_label_2 {
    font-size: 15px;
  }

  .account_details .profile_pic_image {
    width: 70px;
    height: 70px;
  }

  .account_details .button_centered button {
    font-size: 12px;
  }
}

@media only screen and (max-width: 599px) {
  .birth_infos_form {
    height: 537px;
  }

  .birth_infos_form .react-datepicker__time-box  {
    width: 100% !important;
  }

  .birth_infos_form .react-datepicker__time-container  {
    float: inherit;
    width: 100%;
    margin-top: 285px;
  }
}

@media only screen and (max-width: 399px) {
  .account_details .profile_label_1 {
    font-size: 20px;
  }

  .account_details .profile_label_2 {
    font-size: 12px;
  }

  .account_details .button_centered button {
    font-size: 12px;
  }
}
